<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            SMS Report
            <v-spacer></v-spacer>
            <v-download-column-select
              v-if="form.items.data.length > 0"
              :showPDF="false"
              :downloadColumns="dynamicColumns"
              @onDownload="downloadSmsReport"
            >
              DOWNLOAD SMS RECORD
            </v-download-column-select>
          </v-card-title>
          <v-card-title class="title">
            <v-flex xs2>
              <v-calendar-field
                id-val="from_date"
                v-model="frmDate"
                label="From Date"
              >
              </v-calendar-field>
            </v-flex>
            <v-flex xs2>
              <v-calendar-field
                id-val="to_date"
                v-model="toDate"
                label="To Date"
              >
              </v-calendar-field>
            </v-flex>
            <v-flex xs2>
               <v-select
                  outlined
                  dense
                  :items="sms_for"
                  label="Select Role"
                  v-model="sms_user_role"
                />
            </v-flex>
            <v-flex xs2>
               <v-select
                  outlined
                  dense
                  :items="group_types"
                  label="Select Group"
                  v-model="group_type"
                />
            </v-flex>
            <v-flex xs2>
              <v-user-search-field
                v-on:onClear="mobile = ''"
                @onSelectUser="searchUser"
              ></v-user-search-field>
            </v-flex>
            <v-flex xs1>
              <search-button permission="batch-create" @action="get()">
              </search-button>
            </v-flex>
            <v-flex xs1>
               <v-btn
                  color="primary"
                  style="margin-top:-20px;float:right"
                  elevation="2"
                  icon
                  @click="moreFilterDialogue=true"
                  ><v-icon>filter_alt</v-icon></v-btn
                >
            </v-flex>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            sort-by="id"
            :footer-props="rowsPerPageItems"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.date }}</td>
                <td class="text-xs-left">{{ item.sms_to }}</td>
                <td class="text-xs-left" width="50%">{{ item.message }}</td>
                <td class="text-xs-center">
                  <!-- <v-badge v-if="item.status === 'sent'"> -->
                    <v-icon v-if="item.status === 'sent'" color="green">check_circle</v-icon>
                    <!-- </v-badge> -->
                  <!-- <v-badge v-if="item.status === 'error'"> -->
                    <v-icon v-if="item.status === 'error'" color="error">cancel</v-icon>
                  <!-- </v-badge> -->
                  <span
                    v-if="item.status === 'queue'"
                    style="background: #5fbae9;color: white;border-radius: 15px;padding: 5px"
                    >queue</span
                  >
                  <!--                                <v-badge :class="props.item.status === 'sent' ?  'badge-green' : (props.item.status === 'error' ? 'badge-red' :'badge-yellow' )">{{props.item.status}}</v-badge></td>-->
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
     <v-dialog v-model="moreFilterDialogue" persistent max-width="550">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>

              <v-card-title class="title pa-3">
                <br />
                <v-flex xs12>
                  <v-select
                    :items="sms_types"
                    v-model="sms_type"
                    autocomplete="off"
                    label="Select Sms Types"
                    outlined
                    dense
                    class="pa-0"
                  ></v-select>
                </v-flex>
                <v-flex>
                  <v-grade-field v-model="sms_grade"></v-grade-field>
                </v-flex>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false,sms_grade='',sms_type=''"
                  >Close</v-btn
                >
                <v-btn color="success" small @click="get()">
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  </v-container>
</template>
<script>

import { mapState } from "vuex";
import Form from "@/library/Form";
// const NepaliDate = require('nepali-date');
import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from '../../../../library/helpers';
const NepaliDate = require('nepali-date');
const nd = new NepaliDate();


export default {
  mixins: [Mixins],

  data: () => ({
    moreFilterDialogue:false,
    isLoading: false,
    co_ordinator: [],
    model: null,
    entries: [],
    form: new Form(
      {
        title: "",
        message: "",
      },
      "/api/sms/queue"
    ),
    search: "",
    searchCo: "",
    teacherList: [],
    rowsPerPageItems: { "items-per-page-options": [5, 10, 25, 50, 75,100] },

    // pagination: {
    //   rowsPerPage: 10,
    // },
    frmDate: "",
    toDate: "",
    sms_user_role:'',
    sms_type:'',
    mobile:"",
    group_type:"",
    sms_grade:"",
    sms_for:[
      {text:"All", value:'all'},
      {text:"Notice", value:'notice'},
      {text:"Birthday", value:'birthday'},
      {text:"Birthday", value:'birthday'},
      {text:"Fee Receipt", value:'fee-receipt'},
      {text:"General Receipt", value:'general-receipt'},
    ],
    group_types: [
      { text: "All", value: "all" },
      { text: "Staff", value: "staff" },
      { text: "Guardian", value: "guardian" },
    ],
    sms_types: [
      { text: "All", value: "all" },
      { text: "Single", value: "s" },
      { text: "Bulk", value: "b" },
      { text: "Group", value: "g" },
    ],
    downloadColumns:[
      {name:"Name",value:'name',selected:true},
      {name:"Company",value:'company',selected:true},
      {name:"Mobile",value:'mobile',selected:true},
      {name:"Message",value:'message',selected:true},
      {name:"Sms Status",value:'status',selected:true},
      {name:"Sms Type",value:'sms_type',selected:true},
      {name:"Sms Group",value:'group_type',selected:true},
      {name:"Sms For", value:'sms_for',selected:true},
      // {name:"Grade", value:'grade',selected:true},
      {name:"Via", value:'via',selected:true},
    ],
    headers: [
      { text: "#", align: "left", value: "id", width: 50, sortable: false },
      { text: "Date", align: "left", value: "date", sortable: false },
      { text: "Mobile", align: "left", value: "mobile", sortable: false },
      { text: "Message", align: "left", value: "Message", sortable: false },
      { text: "Status", align: "center", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
     dynamicColumns() {
      let columns = [];
      this.downloadColumns.map((item) => {
          columns.push(item);
      });
      return columns;
    },
  },

  watch: {
    pagination: function() {
        this.get();
    },
    batch: function(value) {
      //   this.get();
    },
  },
  methods: {
   
    searchUser(user) {
      if(user) this.mobile = user.primary_mobile;
    },
    // queryString() {
    //   let json = this.pagination;
    //   return (
    //     "?" +
    //     Object.keys(json)
    //       .map(function(key) {
    //         if (![null, undefined].includes(json[key]))
    //           return (
    //             encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
    //           );
    //       })
    //       .join("&") +
    //     "&fromDate=" +
    //     this.dateFormat(this.frmDate) +
    //     "&toDate=" +
    //     this.dateFormat(this.toDate)
    //   );
    // },
    get(params) {
      let extraParams =
        "fromDate=" +
        this.dateFormat(this.frmDate) +
        "&toDate=" +
        this.dateFormat(this.toDate)+
        "&sms_for="+
        this.sms_user_role+
        "&mobile="+
        this.mobile+
        "&group_type="+
        this.group_type+
        "&sms_type="+this.sms_type+
        "&sms_grade="+this.sms_grade;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    dateFormat(data) {
      if (data.length === 8)
        return (data =
          data.substr(0, 4) + "-" + data[4] + data[5] + "-" + data.slice(-2));
      else return data;
    },
    downloadSmsReport({ type, columns, columnString }){

redirectDownloadUrl({uri: '/download/sms-report',
        queryString:`columns=${columnString}`+ "&fromDate=" +
          this.dateFormat(this.frmDate) +
          "&toDate=" +
          this.dateFormat(this.toDate)+
          "&sms_for="+
          this.sms_user_role+
          "&mobile="+
          this.mobile+
          "&group_type="+
          this.group_type+
          "&sms_type="+
          this.sms_type+
          "&sms_grade="+
          this.sms_grade
});

      //  this.$rest.get("/api/download/sms-report").then(({ data }) => {
      //   let url =
      //     data.data.download_url +
      //     `?columns=${columnString}`+ "&fromDate=" +
      //     this.dateFormat(this.frmDate) +
      //     "&toDate=" +
      //     this.dateFormat(this.toDate)+
      //     "&sms_for="+
      //     this.sms_user_role+
      //     "&mobile="+
      //     this.mobile+
      //     "&group_type="+
      //     this.group_type;
      //     console.log(url,'url');
      //     // window.open(url);
      // });
    }
  },
};
</script>
<style lang="scss" scoped>
.badge-green {
  background: green;
  color: white;
  border-radius: 15px;
  padding: 5px;
}

.badge-red {
  background: red;
  color: white;
  border-radius: 15px;
  padding: 5px;
}

.badge-yellow {
  background: yellow;
  color: white;
  border-radius: 15px;
  padding: 5px;
}
</style>
